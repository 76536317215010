<template>
  <div>
    <!--  Prompt -->
    <CBox m="50px auto" w="80%">
      <CBox
        w="100%"
        h="min-content"
        bg="white"
        border="1px"
        borderColor="borderBox"
      >
        <CBox mx="32px" my="20px" v-if="!isLoading">
          <CBox d="flex" justifyContent="space-between">
            <CText fontWeight="bold" color="fontMain" my="10px" fontSize="26px">
              Prompt
            </CText>
            <CBox align="right" mb="25px" mt="10px">
              <ButtonBlue
                :label="showPrompt ? 'Hide' : 'Show'"
                size="sm"
                rounded="45px"
                @click.native="togglePrompt()"
              />
            </CBox>
          </CBox>

          <CBox v-if="showPrompt">
            <CFormControl mt="10px">
              <CGrid templateColumns="1fr 4fr" :gap="6" w="100%">
                <CFormLabel color="fontMain"
                  >Profil
                  <CSpinner
                    v-if="promptStatus.profile"
                    color="blue.500"
                    size="sm"
                  />
                </CFormLabel>
                <CTextarea
                  placeholder="Masukan prompt untuk data profile"
                  v-model="prompt.profile"
                  :value="prompt.profile"
                  :disabled="promptStatus.profile"
                  h="100px"
                ></CTextarea>
              </CGrid>
            </CFormControl>

            <CFormControl mt="10px">
              <CGrid templateColumns="1fr 4fr" :gap="6" w="100%">
                <CFormLabel color="fontMain"
                  >Pendidikan
                  <CSpinner
                    v-if="promptStatus.education"
                    color="blue.500"
                    size="sm"
                  />
                </CFormLabel>
                <CTextarea
                  placeholder="Masukan prompt untuk data pendidikan"
                  v-model="prompt.education"
                  :value="prompt.education"
                  :disabled="promptStatus.education"
                  h="100px"
                ></CTextarea>
              </CGrid>
            </CFormControl>

            <CFormControl mt="10px">
              <CGrid templateColumns="1fr 4fr" :gap="6" w="100%">
                <CFormLabel color="fontMain"
                  >Pengalaman Projek
                  <CSpinner
                    v-if="promptStatus.experience"
                    color="blue.500"
                    size="sm"
                  />
                </CFormLabel>
                <div>
                  <c-radio-group v-model="experienceBehaviour">
                    <c-radio value="replace">Replace</c-radio>
                    <c-radio value="append">Append</c-radio>
                  </c-radio-group>
                  <CTextarea
                    placeholder="Masukan prompt untuk data pengalaman projek"
                    v-model="prompt.experience"
                    :value="prompt.experience"
                    :disabled="promptStatus.experience"
                    h="100px"
                    mt="5px"
                  ></CTextarea>
                  <div>{{ prompt.experience.length }} karakter</div>
                  <CAlert
                    status="error"
                    fontSize="13px"
                    p="2"
                    fontWeight="bold"
                    my="2"
                    v-if="prompt.experience.length >= limitCharacter.experience"
                  >
                    Input Prompt pengalaman projek sudah melewati batas (max
                    {{ limitCharacter.experience }} karakter)
                  </CAlert>
                </div>
              </CGrid>
            </CFormControl>

            <CFormControl mt="10px">
              <CGrid templateColumns="1fr 4fr" :gap="6" w="100%">
                <CFormLabel color="fontMain"
                  >Skill
                  <CSpinner
                    v-if="promptStatus.skill"
                    color="blue.500"
                    size="sm"
                  />
                </CFormLabel>
                <CTextarea
                  placeholder="Masukan prompt untuk data skill"
                  v-model="prompt.skill"
                  :value="prompt.skill"
                  :disabled="promptStatus.skill"
                  h="100px"
                ></CTextarea>
              </CGrid>
            </CFormControl>

            <CFormControl mt="10px">
              <CGrid templateColumns="1fr 4fr" :gap="6" w="100%">
                <CFormLabel color="fontMain">Bahasa</CFormLabel>
                <c-menu v-slot="{ isOpen }">
                  <c-menu-button
                    :is-active="isOpen"
                    :right-icon="isOpen ? 'chevron-up' : 'chevron-down'"
                    bg="white"
                    border="1px solid #333"
                  >
                    {{ prompt.language || "pilih bahasa" }}
                  </c-menu-button>
                  <c-menu-list>
                    <c-menu-item
                      @click="selectLanguageHandler('Indonesia')"
                      fontWeight="bold"
                    >
                      Indonesia
                    </c-menu-item>
                    <c-menu-item
                      @click="selectLanguageHandler('Inggris')"
                      fontWeight="bold"
                    >
                      Inggris
                    </c-menu-item>
                  </c-menu-list>
                </c-menu>
              </CGrid>
            </CFormControl>

            <CBox align="right" mb="25px" mt="10px">
              <ButtonBlue
                :label="loadingGenerateCv ? 'Loading..' : 'Generate'"
                size="md"
                rounded="45px"
                @click.native="onGenerateCv()"
                :disabled="validateGenerate"
              />
            </CBox>
          </CBox>
        </CBox>
      </CBox>
    </CBox>

    <CBox m="50px auto" w="80%">
      <CBox
        w="100%"
        h="min-content"
        bg="white"
        border="1px"
        borderColor="borderBox"
      >
        <CBox v-if="isLoading">
          <CBox h="20px" />
          <Loading v-if="isLoading" />
        </CBox>

        <!-- Main Page -->
        <CBox mx="32px" my="20px" v-if="!isLoading">
          <CText fontWeight="bold" color="fontMain" my="10px" fontSize="26px">
            Custom CV
          </CText>

          <!-- profile -->
          <CBox maxH="calc(100vh - 310px)" overflowY="auto">
            <CFormControl>
              <CGrid templateColumns="1fr 4fr" :gap="6" w="100%">
                <CFormLabel color="fontMain">Nama</CFormLabel>
                <CInput
                  type="text"
                  placeholder="Tuliskan nama anda disini"
                  v-model="form.full_name"
                ></CInput>
              </CGrid>
            </CFormControl>
            <CFormControl mt="10px">
              <CGrid templateColumns="1fr 4fr" :gap="6" w="100%">
                <CFormLabel color="fontMain">Profesi</CFormLabel>
                <CInput
                  type="text"
                  placeholder="Tuliskan profesi anda disini"
                  v-model="form.professional_statement"
                ></CInput>
              </CGrid>
            </CFormControl>
            <CFormControl mt="10px">
              <CGrid templateColumns="1fr 4fr" :gap="6" w="100%">
                <CFormLabel color="fontMain">Email</CFormLabel>
                <CInput
                  type="text"
                  placeholder="Tuliskan email anda disini"
                  v-model="form.email"
                ></CInput>
              </CGrid>
            </CFormControl>
            <CFormControl mt="10px">
              <CGrid templateColumns="1fr 4fr" :gap="6" w="100%">
                <CFormLabel color="fontMain">No Telepon</CFormLabel>
                <CInput
                  type="text"
                  placeholder="Tuliskan no telepon anda disini"
                  v-model="form.phone_no"
                ></CInput>
              </CGrid>
            </CFormControl>
            <CFormControl mt="10px">
              <CGrid templateColumns="1fr 4fr" :gap="6" w="100%">
                <CFormLabel color="fontMain">Tanggal lahir</CFormLabel>
                <CInput
                  type="text"
                  placeholder="Tuliskan tanggal lahir anda disini"
                  v-model="form.birth_date"
                ></CInput>
              </CGrid>
            </CFormControl>
            <CFormControl mt="10px">
              <CGrid templateColumns="1fr 4fr" :gap="6" w="100%">
                <CFormLabel color="fontMain">Github</CFormLabel>
                <CInput
                  type="text"
                  placeholder="Tuliskan link github anda disini"
                  v-model="form.github"
                ></CInput>
              </CGrid>
            </CFormControl>
            <CFormControl mt="10px">
              <CGrid templateColumns="1fr 4fr" :gap="6" w="100%">
                <CFormLabel color="fontMain">Linkedin</CFormLabel>
                <CInput
                  type="text"
                  placeholder="Tuliskan link linkedin anda disini"
                  v-model="form.linkedin_id"
                ></CInput>
              </CGrid>
            </CFormControl>
            <CFormControl mt="10px">
              <CGrid templateColumns="1fr 4fr" :gap="6" w="100%">
                <CFormLabel color="fontMain">Deskripsi</CFormLabel>
                <CTextarea
                  placeholder="Tambahkan deskripsi singkat, hobi, atau kepribadian anda"
                  v-model="form.description"
                  :value="form.description"
                  h="200px"
                ></CTextarea>
              </CGrid>
            </CFormControl>
          </CBox>

          <!-- Skill -->
          <CBox mt="50px">
            <CText fontWeight="bold" color="fontMain" my="10px" fontSize="20px">
              Keahlian
            </CText>
            <CSimpleGrid
              :columns="3"
              :spacing="3"
              maxH="calc(100vh - 310px)"
              overflowY="auto"
            >
              <CBox
                border="1px"
                borderColor="borderBox"
                maxH="150px"
                minH="150px"
                overflowY="auto"
                v-for="(item, counter) in form.skill"
                :key="counter"
              >
                <CBox mx="10px" mt="10px" mb="20px">
                  <CBox d="flex" justifyContent="space-between">
                    <CText color="fontCountService">#{{ counter + 1 }}</CText>
                    <CBox d="flex">
                      <CImage
                        v-if="!item.isEdit"
                        class="pointer"
                        @click.native="item.isEdit = true"
                        :src="require('@/assets/icon/pencil-white.png')"
                        w="15px"
                        h="15px"
                      />
                      <CImage
                        v-if="!item.isEdit"
                        class="pointer"
                        @click.native="deleteSkill(counter)"
                        :src="require('@/assets/icon/close-white.png')"
                        w="15px"
                        h="15px"
                        ml="5px"
                      />
                      <ButtonBlue
                        v-if="item.isEdit"
                        label="Simpan"
                        size="sm"
                        rounded="45px"
                        @click.native="item.isEdit = false"
                      />
                    </CBox>
                  </CBox>
                  <CBox mt="20px">
                    <CFormControl>
                      <CFormLabel color="activeMenu" fontSize="15px"
                        >Nama Keahlian</CFormLabel
                      >
                      <CInput
                        v-if="item.isEdit"
                        type="text"
                        placeholder="Nama Keahlian"
                        v-model="item.name"
                      />
                      <CText v-if="!item.isEdit" color="fontMain">{{
                        item.name
                      }}</CText>
                    </CFormControl>
                    <!-- <CFormControl mt="10px">
                      <CFormLabel color="activeMenu" fontSize="15px"
                        >Rating (1-10)</CFormLabel
                      >
                      <CInput
                        v-if="item.isEdit"
                        type="text"
                        placeholder="rating"
                        v-model="item.rating"
                      />
                      <CText v-if="!item.isEdit" color="fontMain">{{
                        item.rating
                      }}</CText>
                    </CFormControl> -->
                  </CBox>
                </CBox>
              </CBox>
              <CBox
                border="1px"
                borderColor="borderBox"
                v-if="isAddSkill"
                maxH="150px"
              >
                <CImage
                  class="pointer"
                  @click.native="addSkill()"
                  :src="require('@/assets/img/add-service.png')"
                  w="100%"
                  h="full"
                  objectFit="cover"
                />
              </CBox>
            </CSimpleGrid>
          </CBox>

          <!-- education -->
          <CBox mt="50px">
            <CText fontWeight="bold" color="fontMain" my="10px" fontSize="20px">
              Pendidikan
            </CText>
            <CSimpleGrid
              :columns="3"
              :spacing="3"
              maxH="calc(100vh - 310px)"
              overflowY="auto"
            >
              <CBox
                border="1px"
                borderColor="borderBox"
                maxH="300px"
                minH="300px"
                overflowY="auto"
                v-for="(item, counter) in form.education"
                :key="counter"
              >
                <CBox mx="10px" mt="10px" mb="20px">
                  <CBox d="flex" justifyContent="space-between">
                    <CText color="fontCountService">#{{ counter + 1 }}</CText>
                    <CBox d="flex">
                      <CImage
                        v-if="!item.isEdit"
                        class="pointer"
                        @click.native="item.isEdit = true"
                        :src="require('@/assets/icon/pencil-white.png')"
                        w="15px"
                        h="15px"
                      />
                      <CImage
                        v-if="!item.isEdit"
                        class="pointer"
                        @click.native="deleteEducation(counter)"
                        :src="require('@/assets/icon/close-white.png')"
                        w="15px"
                        h="15px"
                        ml="5px"
                      />
                      <ButtonBlue
                        v-if="item.isEdit"
                        label="Simpan"
                        size="sm"
                        rounded="45px"
                        @click.native="item.isEdit = false"
                      />
                    </CBox>
                  </CBox>
                  <CBox mt="20px">
                    <CFormControl>
                      <CFormLabel color="activeMenu" fontSize="15px"
                        >Nama Sekolah/Universitas</CFormLabel
                      >
                      <CInput
                        v-if="item.isEdit"
                        type="text"
                        placeholder="Nama Sekolah"
                        v-model="item.school_name"
                      />
                      <CText v-if="!item.isEdit" color="fontMain">{{
                        item.school_name
                      }}</CText>
                    </CFormControl>
                    <CFormControl mt="10px">
                      <CFormLabel color="activeMenu" fontSize="15px"
                        >Tahun Mulai</CFormLabel
                      >
                      <CInput
                        v-if="item.isEdit"
                        type="text"
                        placeholder="Tahun mulai"
                        v-model="item.start_year"
                      />
                      <CText v-if="!item.isEdit" color="fontMain">{{
                        item.start_year
                      }}</CText>
                    </CFormControl>
                    <CFormControl mt="10px">
                      <CFormLabel color="activeMenu" fontSize="15px"
                        >Tahun Selesai</CFormLabel
                      >
                      <CInput
                        v-if="item.isEdit"
                        type="text"
                        placeholder="Tahun selesai"
                        v-model="item.end_year"
                      />
                      <CText v-if="!item.isEdit" color="fontMain">{{
                        item.end_year
                      }}</CText>
                    </CFormControl>
                    <CFormControl mt="10px">
                      <CFormLabel
                        v-if="item.field_of_study || item.isEdit"
                        color="activeMenu"
                        fontSize="15px"
                        >Jurusan</CFormLabel
                      >
                      <CInput
                        v-if="item.isEdit"
                        type="text"
                        placeholder="Jurusan"
                        v-model="item.field_of_study"
                      />
                      <CText
                        v-if="!item.isEdit || !item.field_of_study"
                        color="fontMain"
                        >{{ item.field_of_study }}</CText
                      >
                    </CFormControl>
                  </CBox>
                </CBox>
              </CBox>
              <CBox
                border="1px"
                borderColor="borderBox"
                v-if="isAddEducation"
                maxH="300px"
              >
                <CImage
                  class="pointer"
                  @click.native="addEducation()"
                  :src="require('@/assets/img/add-service.png')"
                  w="100%"
                  h="full"
                  objectFit="cover"
                />
              </CBox>
            </CSimpleGrid>
          </CBox>

          <!-- experience -->
          <CBox mt="50px">
            <CText fontWeight="bold" color="fontMain" my="10px" fontSize="20px">
              Pengalaman Projek
            </CText>
            <CSimpleGrid
              :columns="3"
              :spacing="3"
              maxH="calc(100vh - 310px)"
              overflowY="auto"
            >
              <CBox
                border="1px"
                borderColor="borderBox"
                maxH="450px"
                minH="450px"
                overflowY="auto"
                v-for="(item, counter) in form.experience"
                :key="counter"
              >
                <CBox mx="10px" mt="10px" mb="20px">
                  <CBox d="flex" justifyContent="space-between">
                    <CText color="fontCountService">#{{ counter + 1 }}</CText>
                    <CBox d="flex">
                      <CImage
                        v-if="!item.isEdit"
                        class="pointer"
                        @click.native="item.isEdit = true"
                        :src="require('@/assets/icon/pencil-white.png')"
                        w="15px"
                        h="15px"
                      />
                      <CImage
                        v-if="!item.isEdit"
                        class="pointer"
                        @click.native="deleteExperience(counter)"
                        :src="require('@/assets/icon/close-white.png')"
                        w="15px"
                        h="15px"
                        ml="5px"
                      />
                      <ButtonBlue
                        v-if="item.isEdit"
                        label="Simpan"
                        size="sm"
                        rounded="45px"
                        @click.native="item.isEdit = false"
                      />
                    </CBox>
                  </CBox>
                  <CBox mt="20px">
                    <CFormControl mt="10px">
                      <CFormLabel color="activeMenu" fontSize="15px"
                        >Nama Perusahaan</CFormLabel
                      >
                      <CInput
                        v-if="item.isEdit"
                        type="text"
                        placeholder="Nama Perusahaan"
                        v-model="item.job_title"
                      />
                      <CText v-if="!item.isEdit" color="fontMain">{{
                        item.job_title
                      }}</CText>
                    </CFormControl>
                    <CFormControl>
                      <CFormLabel color="activeMenu" fontSize="15px"
                        >Nama Projek</CFormLabel
                      >
                      <CInput
                        v-if="item.isEdit"
                        type="text"
                        placeholder="Nama Projek"
                        v-model="item.company.name"
                      />
                      <CText v-if="!item.isEdit" color="fontMain">{{
                        item.company.name
                      }}</CText>
                    </CFormControl>
                    <CFormControl mt="10px">
                      <CFormLabel color="activeMenu" fontSize="15px"
                        >Tanggal Mulai</CFormLabel
                      >
                      <CInput
                        v-if="item.isEdit"
                        type="text"
                        placeholder="Tanggal mulai"
                        v-model="item.job_start_date"
                      />
                      <CText v-if="!item.isEdit" color="fontMain">{{
                        item.job_start_date
                      }}</CText>
                    </CFormControl>
                    <CFormControl mt="10px">
                      <CFormLabel color="activeMenu" fontSize="15px"
                        >Tanggal Selesai</CFormLabel
                      >
                      <CInput
                        v-if="item.isEdit"
                        type="text"
                        placeholder="Tanggal selesai"
                        v-model="item.job_end_date"
                      />
                      <CText v-if="!item.isEdit" color="fontMain">{{
                        item.job_end_date
                      }}</CText>
                    </CFormControl>
                    <CFormControl mt="10px">
                      <CFormLabel color="activeMenu" fontSize="15px"
                        >Deskripsi</CFormLabel
                      >
                      <CInput
                        v-if="item.isEdit"
                        type="text"
                        placeholder="Deskripsi"
                        v-model="item.description"
                      />
                      <CText v-if="!item.isEdit" color="fontMain">{{
                        item.description
                      }}</CText>
                    </CFormControl>
                  </CBox>
                </CBox>
              </CBox>
              <CBox
                border="1px"
                borderColor="borderBox"
                maxH="450px"
                v-if="isAddExperience"
              >
                <CImage
                  class="pointer"
                  @click.native="addExperience()"
                  :src="require('@/assets/img/add-service.png')"
                  w="100%"
                  h="full"
                  objectFit="cover"
                />
              </CBox>
            </CSimpleGrid>
          </CBox>
        </CBox>

        <CDivider borderColor="borderBox" border="1.5px" />
        <CBox
          justifyContent="flex-end"
          m="20px"
          v-if="!isLoading"
          d="flex"
          gap="10px"
        >
          <ButtonBlue label="Unduh CV" @click.native="downloadCV()" />
          <ButtonBlue label="View CV" @click.native="viewCV()" />
          <ButtonBlue
            label="Simpan"
            @click.native="onSubmit()"
            :disabled="disabledButton"
          />
        </CBox>
      </CBox>
    </CBox>
  </div>
</template>

<script>
import { GET_JWT } from "@/store/auth.module";
import {
  GET_CV_TOKEN,
  SAVE_CV_TOKEN,
  GENERATE_CV_DATA,
} from "@/store/cv.module";
import Loading from "@/components/Loading.vue";
import ButtonBlue from "@/components/button/ButtonBlue.vue";
import Swal from "sweetalert2";
import config from "@/ConfigProvider";
// const FE_URL = config.value("FE_URL");
const CV_URL = config.value("CV_URL");

export default {
  metaInfo: {
    title: "Custom CV",
    titleTemplate: "%s - Gethired.id",
  },
  components: {
    Loading,
    ButtonBlue,
  },
  data() {
    return {
      showPrompt: true,
      isOpen: false,
      isLoading: false,
      loadingGenerateCv: false,
      user: {},
      disabledButton: false,
      experienceBehaviour: "replace",
      limitCharacter: {
        experience: 4000,
      },
      prompt: {
        profile: "",
        experience: "",
        skill: "",
        education: "",
        language: "",
      },
      promptStatus: {
        profile: false,
        experience: false,
        skill: false,
        education: false,
      },
      form: {
        full_name: "",
        professional_statement: "",
        email: "",
        phone_no: "",
        birth_date: "",
        description: "",
        github: "",
        linkedin_id: "",
        education: [],
        experience: [],
        skill: [],
      },
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    validateGenerate() {
      let result = false;
      for (const key in this.prompt) {
        const val = this.prompt[key];
        if (!val.length) {
          result = true;
          break;
        }
      }

      let loadingPrompt = false;
      for (const key in this.promptStatus) {
        const val = this.promptStatus[key];
        if (val) {
          loadingPrompt = true;
          break;
        }
      }
      const condition =
        result ||
        loadingPrompt ||
        this.prompt.experience?.length >= this.limitCharacter.experience;

      return condition;
    },
    isAddSkill() {
      let result = true;
      for (const d of this.form.skill) {
        if (d.isEdit) {
          result = false;
          break;
        }
      }
      return result;
    },
    isAddEducation() {
      let result = true;
      for (const d of this.form.education) {
        if (d.isEdit) {
          result = false;
          break;
        }
      }
      return result;
    },
    isAddExperience() {
      let result = true;
      for (const d of this.form.experience) {
        if (d.isEdit) {
          result = false;
          break;
        }
      }
      return result;
    },
  },
  methods: {
    async getData() {
      this.isLoading = true;
      await this.$store.dispatch(GET_JWT);
      const token = this.$route.params.token?.split("-")[1];
      setTimeout(async () => {
        if (token) {
          this.$store
            .dispatch(GET_CV_TOKEN, {
              cvtoken: token,
            })
            .then((res) => {
              const data = res.data.data;

              for (const key in this.form) {
                let item = data?.custom_data[key];
                if (["skill", "education", "experience"].includes(key)) {
                  item = item?.map((it) => ({ ...it, isEdit: false }));
                }
                this.form[key] = item;
              }

              for (const key in this.prompt) {
                let item = data?.prompt_data[key];
                if (item) {
                  this.prompt[key] = item;
                }
              }

              this.isLoading = false;
            })
            .catch(() => {
              Swal.fire({
                title: "Oops . .",
                text: "Link CV is Not Found!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false,
              }).then(() => {
                window.location.href =
                  config.value("FE_URL") + "/employee/profile";
              });
            });
        }
      }, 100);
    },
    downloadCV() {
      const token = this.$route.params.token?.split("-")[1];
      window.open(CV_URL + "/" + "cv-" + token + "-pdf", "_blank");
    },
    viewCV() {
      const token = this.$route.params.token?.split("-")[1];
      window.open(CV_URL + "/" + "cv-" + token + "?template=skyshi", "_blank");
    },
    onSubmit() {
      this.disabledButton = true;
      const token = this.$route.params.token?.split("-")[1];

      const custom_data = { ...this.form };
      custom_data.skill = custom_data.skill.map((item) => ({
        name: item.name,
      }));
      custom_data.education = custom_data.education.map((item) => ({
        school_name: item.school_name,
        start_year: item.start_year,
        end_year: item.end_year,
        field_of_study: item.field_of_study,
      }));
      custom_data.experience = custom_data.experience.map((item) => ({
        job_title: item.job_title,
        company: { name: item.company.name },
        job_start_date: item.job_start_date,
        job_end_date: item.job_end_date,
        description: item.description,
      }));

      const payload = { custom_data, prompt_data: this.prompt };

      this.$store
        .dispatch(SAVE_CV_TOKEN, { cvtoken: token, payload })
        .then(() => {
          this.disabledButton = false;
          Swal.fire({
            title: "Berhasil.",
            text: "Data berhasil tersimpan",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    async onGenerateCv() {
      const token = this.$route.params.token?.split("-")[1];

      // save prompt
      this.$store.dispatch(SAVE_CV_TOKEN, {
        cvtoken: token,
        payload: { prompt_data: this.prompt },
      });

      // generate profile
      this.promptStatus.profile = true;
      this.$store
        .dispatch(GENERATE_CV_DATA, {
          endpoint: "profile",
          payload: {
            profileTxt: this.prompt.profile,
            lang: this.prompt.language,
          },
        })
        .then((res) => {
          this.promptStatus.profile = false;
          const data = res?.data?.data;

          data["description"] = data["description"] ?? data["descriptions"];
          for (const key in data) {
            this.form[key] = data[key];
          }
        });

      // generate education
      this.promptStatus.education = true;
      this.$store
        .dispatch(GENERATE_CV_DATA, {
          endpoint: "education",
          payload: {
            educationTxt: this.prompt.education,
            lang: this.prompt.language,
          },
        })
        .then((res) => {
          this.promptStatus.education = false;

          const data = res?.data?.data;
          console.log("[education]", data);
          for (const key in data) {
            this.form[key] = data[key];
          }
        });

      // generate skill
      this.promptStatus.skill = true;
      this.$store
        .dispatch(GENERATE_CV_DATA, {
          endpoint: "skill",
          payload: {
            skillTxt: this.prompt.skill,
            lang: this.prompt.language,
          },
        })
        .then((res) => {
          this.promptStatus.skill = false;
          const data = res?.data?.data;
          console.log("[skill]", data);
          for (const key in data) {
            this.form[key] = data[key];
          }
        });

      // generate experience
      this.promptStatus.experience = true;
      this.$store
        .dispatch(GENERATE_CV_DATA, {
          endpoint: "experience",
          payload: {
            experienceTxt: this.prompt.experience,
            lang: this.prompt.language,
          },
        })
        .then((res) => {
          const data = res?.data?.data;
          console.log("[experience]", data);
          if (data?.experience) {
            const updatedExperience = data?.experience?.map((item) => {
              const companyName = item?.company?.name;

              return {
                ...item,
                company: { name: item.project_name },
                job_title: companyName,
                description:
                  item?.description +
                  (item.responsibility
                    ? `\n\nResponsibility: \n` +
                      item.responsibility
                        .map(function (el) {
                          return "- " + el;
                        })
                        .join("\n")
                    : "") +
                  (item.team_size ? `\n\nTeam size: ${item.team_size}` : "") +
                  (item.tool ? `\nTools: ${item.tool}` : ""),
              };
            });
            if (this.experienceBehaviour === "replace") {
              this.form.experience = updatedExperience;
            } else {
              this.form.experience = [
                ...this.form.experience,
                ...updatedExperience,
              ];
            }
          }
          this.promptStatus.experience = false;
        });
    },
    togglePrompt() {
      this.showPrompt = !this.showPrompt;
    },
    selectLanguageHandler(lang) {
      this.prompt.language = lang;
    },
    addSkill() {
      this.form.skill.push({
        name: "",
        isEdit: true,
      });
    },
    deleteSkill(id) {
      this.form.skill.splice(id, 1);
    },
    addEducation() {
      this.form.education.push({
        school_name: "",
        end_year: "",
        start_year: "",
        field_of_study: "",
        isEdit: true,
      });
    },
    deleteEducation(id) {
      this.form.education.splice(id, 1);
    },
    addExperience() {
      this.form.experience.push({
        company: {
          name: "",
        },
        job_title: "",
        description: "",
        job_end_date: "",
        job_start_date: "",
        isEdit: true,
      });
    },
    deleteExperience(id) {
      this.form.experience.splice(id, 1);
    },
  },
};
</script>
