import { render, staticRenderFns } from "./CustomCv.vue?vue&type=template&id=5d22265a&"
import script from "./CustomCv.vue?vue&type=script&lang=js&"
export * from "./CustomCv.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CBox: require('@chakra-ui/vue').CBox, CSpinner: require('@chakra-ui/vue').CSpinner, CFormLabel: require('@chakra-ui/vue').CFormLabel, CTextarea: require('@chakra-ui/vue').CTextarea, CGrid: require('@chakra-ui/vue').CGrid, CFormControl: require('@chakra-ui/vue').CFormControl, CRadio: require('@chakra-ui/vue').CRadio, CRadioGroup: require('@chakra-ui/vue').CRadioGroup, CAlert: require('@chakra-ui/vue').CAlert, CMenuButton: require('@chakra-ui/vue').CMenuButton, CMenuItem: require('@chakra-ui/vue').CMenuItem, CMenuList: require('@chakra-ui/vue').CMenuList, CMenu: require('@chakra-ui/vue').CMenu, CInput: require('@chakra-ui/vue').CInput, CImage: require('@chakra-ui/vue').CImage, CSimpleGrid: require('@chakra-ui/vue').CSimpleGrid, CDivider: require('@chakra-ui/vue').CDivider})
